import { createStore } from 'vuex'

export default createStore({
  state: {
    collapsed: false,
    sidebar_current: '/',
    sidebar_openKeys: '/',
    level: 3,
    username:'',
    //collapsed: true, //左侧sidebar状态，false=展开，true=关闭
  },
  mutations: {
    GoMenu (state,value){
      state.collapsed = value
    },
    sidebar_current (state,value){
      state.sidebar_current = value
    },
    username (state,value){
      state.username = value
    },
    sidebar_openKeys (state,value){
      state.sidebar_openKeys = value
    },
    levels (state,value){
      state.levels = value
    },
  },
  actions: {
  },
  modules: {
  }
})
