import { createRouter, createWebHistory } from 'vue-router'
// import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home'),
    meta:{
      title:'管理中心'
    }
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('../views/Login'),
    meta:{
      title:'登录'
    }
  },
  {
    path: '/SoftList',
    name: 'SoftList',
    component: () => import('../views/SoftList'),
    meta:{
      title:'软件列表'
    }
  },
  {
    path: '/Software',
    name: 'Software',
    component: () => import('../views/Software'),
    meta:{
      title:'软件管理'
    }
  },
  {
    path: '/SoftwareDetail',
    name: 'SoftwareDetail',
    component: () => import('../views/SoftwareDetail'),
    meta:{
      title:'软件版本管理'
    }
  },
  {
    path: '/CustomsList',
    name: 'CustomsList',
    component: () => import('../views/CustomsList.vue'),
    meta:{
      title:'客户列表'
    }
  },
  {
    path: '/ContractList',
    name: 'ContractList',
    component: () => import('../views/ContractList.vue'),
    meta:{
      title:'合同列表'
    }
  },
  {
    path: '/RegLogs',
    name: 'RegLogs',
    component: () => import('../views/RegLogs'),
    meta:{
      title:'激活日志'
    }
  },
  {
    path: '/Mac',
    name: 'Mac',
    component: () => import('../views/Mac'),
    meta:{
      title:'网卡查询'
    }
  },
  {
    path: '/MacDetail',
    name: 'MacDetail',
    component: () => import('../views/MacDetail'),
    meta:{
      title:'网卡日志'
    }
  },
  {
    path: '/CreateAnalytics',
    name: 'CreateAnalytics',
    component: () => import('../views/CreateAnalytics'),
    meta:{
      title:'30 日生成量'
    }
  },
  {
    path: '/StaffList',
    name: 'StaffList',
    component: () => import('../views/StaffList'),
    meta:{
      title:'管理员列表'
    }
  },
  {
    path: '/ChangePassword',
    name: 'ChangePassword',
    component: () => import('../views/ChangePassword'),
    meta:{
      title:'修改密码'
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
