<template>
  <div id="app" v-cloak>
    <router-view />
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

html,body{width:100%;height:100%;}
#app{padding:0;margin:0;height:100%;width:100%;}
</style>
