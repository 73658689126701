import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'normalize.css/normalize.css'
import 'element-plus/dist/index.css'
import App from './App.vue'

import Cookies from 'js-cookie'
import axios from 'axios'
import router from './router'
import store from './store'

const app = createApp(App);
app.use(router);
app.use(store);
app.use(ElementPlus);
app.use(Cookies);

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})

app.config.globalProperties.$http = axios;
app.config.globalProperties.Cookies = Cookies
//app.config.productionTip = false
// createApp.use(Cookies);
// createApp.use(axios);


//app.prototype.axios = axios
// axios.defaults.baseURL = '/api';
axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8';

app.mount('#app');
// createApp(App).mount('#app')
